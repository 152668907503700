<script setup>
import {ref} from 'vue'
import {useLocalePath} from 'vue-i18n-routing'

const processedDataMenu = inject('processedDataMenu')

const header = ref()
const mainMenu = ref()
const menuItemsL0 = ref([])

const showHeader = ref('')
const positionHeader = ref('top-position')
const lastScrollPosition = ref(0)

const localePath = useLocalePath()

// open/close menus
function openMainMenu(event) {
  // if we are closing - remove active from menu-item--level-0
  if (header.value.classList.contains('show-menu')) {
    menuItemsL0.value.forEach((item) => {
      item.classList.remove('active')
    })
  }

  header.value.classList.toggle('show-menu')
  document.querySelector('body').classList.toggle('layer-disable')
  document.querySelector('html').classList.toggle('h-noscroll')
}

// open/close submenus
function openSubmenu(event) {
  menuItemsL0.value.forEach((item) => {
    item.classList.remove('active')
  })
  event.target.closest('.menu-item--level-0').classList.add('active')
}

// back from submenu submenus
function backFromSubmenu(event) {
  menuItemsL0.value.forEach((item) => {
    item.classList.remove('active')
  })

  event.target.closest('.menu-item--level-0').classList.remove('active')
}

function windowScroll() {
  const currentScrollPosition = window.scrollY

  if (
    currentScrollPosition > lastScrollPosition.value &&
    currentScrollPosition > 0
  ) {
    if (!header.value.classList.contains('show-menu'))
      showHeader.value = 'hidden'
  } else {
    showHeader.value = ''
  }

  if (currentScrollPosition == 0) {
    positionHeader.value = 'top-position'
  } else {
    positionHeader.value = ''
  }

  lastScrollPosition.value = currentScrollPosition
}

const router = useRouter()

const inputTrick = ref('')

onMounted(() => {
  window.addEventListener('scroll', windowScroll)
  inputTrick.value = makeId(4)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', windowScroll)
})

function goToSearchPage(event) {
  const input = event.target.nextElementSibling
  const inputValue = input.value

  if (inputValue && inputValue != '') {
    router.push({
      path: localePath('/search'),
      query: {search: inputValue},
    })
  }
}

function makeId(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

// watch for route changes and close the menu (remove helpers classes)
router.afterEach((to, from) => {
  // console.log('AFTER INSIDE')
  // console.log('   - from: ', from)
  // console.log('   - to: ', to)
  // console.log('   - header: ', header)

  if (header && header.value) {
    header.value.classList.remove('show-menu')
  }
  menuItemsL0.value.forEach((item) => {
    item.classList.remove('active')
  })
  document.querySelector('body').classList.remove('layer-disable')
  document.querySelector('html').classList.remove('h-noscroll')
  inputTrick.value = makeId(4)
  // console.log('AFTER INSIDE END')
})
</script>

<template>
  <header class="header" :class="[showHeader, positionHeader]" ref="header">
    <div class="header-layer"></div>
    <div class="header-wrapper">
      <div class="header-logo-wrapper">
        <NuxtLink :to="localePath('/')" class="sensible">
          <svg class="icon icon--logo icon--white-fill">
            <use xlink:href="#logo"></use>
          </svg>
        </NuxtLink>
      </div>
      <div class="header-action-wrapper" v-if="!$nuxt.is404">
        <div class="menu-action menu-action--store menu-action--store-desktop">
          <NuxtLink
            to="https://shop.pininfarina.it/"
            class="btn btn--primary-light sensible"
            >{{ $t('store_label') }}</NuxtLink
          >
        </div>
        <div
          class="menu-action menu-action--collectors menu-action--collectors-desktop">
          <NuxtLink
            :to="localePath('/classiche')"
            class="btn btn--primary-light sensible"
            >{{ $t('classiche_label') }}</NuxtLink
          >
        </div>
        <div
          class="menu-action menu-action--burger sensible"
          @click="openMainMenu">
          <!-- <div class="menu-action-label">
            {{ $t('menu_label') }}
          </div> -->
          <div class="menu-action-icon">
            <div class="bar bar--1"></div>
            <div class="bar bar--2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="full-menu" v-if="!$nuxt.is404">
      <div class="full-menu-body-external">
        <div class="full-menu-body">
          <nav class="menu-main" ref="mainMenu">
            <ul class="menu-list">
              <li
                v-for="(item, index) in processedDataMenu?.menu_items"
                :key="index"
                class="static menu-item menu-item--level-0 has-submenu"
                ref="menuItemsL0">
                <div class="menu-nolink sensible" @click="openSubmenu">
                  <div class="menu-nolink-number">0{{ index + 1 }}</div>
                  <div class="menu-nolink-label">{{ item.label }}</div>
                </div>
                <ul class="menu-list">
                  <li
                    class="menu-nolink menu-item menu-item--level-1"
                    @click="backFromSubmenu">
                    <div class="menu-nolink-number">
                      <svg
                        class="icon icon--btn-round icon--white-stroke icon--rotate-180">
                        <use xlink:href="#icon-arrow"></use>
                      </svg>
                    </div>
                    <div class="menu-nolink-label">{{ item.label }}</div>
                  </li>
                  <li
                    v-for="(subitem, index) in item.submenu"
                    :key="index"
                    class="menu-item menu-item--level-1">
                    <NuxtLink
                      :to="localePath(subitem.path)"
                      class="menu-link sensible"
                      >{{ subitem.label }}
                      <div class="menu-link-btn btn btn--round-light">
                        <svg class="icon icon--btn-round">
                          <use xlink:href="#icon-arrow"></use>
                        </svg>
                      </div>
                    </NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <div class="full-menu-body-secondary">
            <SharedSecondaryMenu
              class="footer-secondary-menu footer-secondary-menu--mobile" />
            <!-- <div
              class="menu-search-link-wrapper menu-search-link-wrapper--mobile">
              <div class="menu-search-link" @click="goToSearchPage">
                <div class="menu-search-btn btn btn--round-light">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-search"></use>
                  </svg>
                </div>
              </div>
              <input
                class="menu-search-input"
                type="text"
                :name="'search-input-' + inputTrick"
                :placeholder="$t('search_keyword')"
                autocomplete="new-search" />
            </div> -->

            <div
              class="full-menu-body-secondary-store full-menu-body-secondary-store--mobile">
              <NuxtLink
                :to="localePath('/classiche')"
                class="btn btn--primary-light sensible btn--collectors"
                >Classiche</NuxtLink
              >
              <NuxtLink
                to="https://shop.pininfarina.it/"
                class="btn btn--primary-light sensible"
                >{{ $t('store_label') }}</NuxtLink
              >
            </div>
          </div>
        </div>
      </div>

      <div class="full-menu-footer">
        <div class="full-menu-footer-left">
          <SharedLanguageMenu />
        </div>
        <div class="full-menu-footer-center">
          <!-- <div
            class="menu-action menu-action--search menu-action--search-desktop">
            <div
              class="menu-search-link-wrapper menu-search-link-wrapper--desktop sensible">
              <div class="menu-search-link" @click="goToSearchPage">
                <div class="menu-search-btn btn btn--round-light">
                  <svg class="icon icon--btn-round">
                    <use xlink:href="#icon-search"></use>
                  </svg>
                </div>
              </div>
              <input
                class="menu-search-input"
                type="text"
                :name="'search-input-' + inputTrick"
                :placeholder="$t('search_keyword')"
                autocomplete="new-search" />
            </div>
          </div> -->
        </div>
        <div class="full-menu-footer-right">
          <SharedSecondaryMenu
            class="footer-secondary-menu footer-secondary-menu--desktop" />
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  backdrop-filter: blur(4px);
  background-color: rgba($color-blue, 0.8);
  height: $header-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(0);
  transition: background-color $transition-default, transform ease-out 250ms;
  z-index: 3;

  &.hidden {
    transform: translateY(-100%);
  }

  &.top-position {
    background-color: transparent;
  }

  &.show-menu {
    background-color: $color-blue;

    .header-layer {
      opacity: 1;
      pointer-events: all;
    }

    .menu-action-label {
      opacity: 0;
      overflow: hidden;
      transition: opacity $transition-default, width $transition-default !important;
      width: 0 !important;
    }

    .menu-action {
      &.menu-action--search {
        opacity: 1;
        transition: opacity $transition-default 275ms,
          width $transition-default 275ms;
        width: col-width(7);

        &.menu-action--search-desktop {
          display: none;

          @include mq(md) {
            display: block;
          }
        }
      }

      &.menu-action--store {
        opacity: 1;
        pointer-events: all;
        transition: opacity $transition-default 275ms;
      }

      &.menu-action--collectors {
        opacity: 1;
        pointer-events: all;
        transition: opacity $transition-default 275ms;
      }

      &.menu-action--burger {
        .menu-action-icon {
          &:hover {
            background-color: $color-white;
            border-color: $color-white;

            .bar {
              background-color: $color-blue;
            }
          }

          .bar {
            &.bar--1 {
              transform: translate(2.5px, -3px) rotate(45deg);
              transform-origin: top left;
            }

            &.bar--2 {
              transform: translate(-2px, -6px) rotate(-45deg);
              transform-origin: top right;
            }
          }
        }
      }
    }

    .full-menu {
      opacity: 1;
      pointer-events: all;
      z-index: 2;
    }
  }

  .header-layer {
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    transition: opacity $transition-default;
    top: $header-height;
  }

  .header-wrapper {
    @include make-col-ready();
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
    }
  }
  .header-logo-wrapper {
    margin-right: pxrem(40);

    @include mq(md) {
      @include make-col(8);
      margin-right: 0;
    }
  }

  .header-action-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include mq(md) {
      @include make-col(13);
      @include make-col-offset(1);
    }
  }

  .menu-action {
    &.menu-action--burger {
      align-items: center;
      display: flex;

      .menu-action-label {
        display: none;

        @include mq(md) {
          @include label($color-white);
          display: block;
          margin-right: pxrem(20);
          text-align: right;
          transition: opacity $transition-default, width $transition-default;
          transition-delay: 275ms;
          user-select: none;
          // width: col-width(2);
          will-change: width;
        }
      }

      .menu-action-icon {
        aspect-ratio: 1;
        background-color: transparent;
        border: 1px solid $color-gray-blue;
        border-radius: 50%;
        flex-shrink: 0;
        height: pxrem(40);
        position: relative;
        transition: background-color $transition-default,
          border-color $transition-default, color $transition-default;
        width: pxrem(40);

        &:hover {
          background-color: $color-white;

          .bar {
            background-color: $color-blue;
          }
        }

        .bar {
          background-color: $color-white;
          height: pxrem(1);
          position: absolute;
          transition: transform $transition-default;
          width: pxrem(14);

          &.bar--1 {
            left: pxrem(12);
            top: pxrem(17);
          }

          &.bar--2 {
            bottom: pxrem(17);
            left: pxrem(12);
          }
        }
      }
    }

    &.menu-action--store,
    &.menu-action--collectors {
      opacity: 0;
      pointer-events: none;
      transition: opacity $transition-default;

      &.menu-action--store-desktop,
      &.menu-action--collectors-desktop {
        display: none;

        @include mq(md) {
          display: block;
          margin-right: pxrem(10);
        }
      }
      &.menu-action--collectors-desktop {
        pointer-events: all;
      }
    }

    &.menu-action--collectors {
      opacity: 1;
    }

    &.menu-action--search {
      margin-right: auto;
      opacity: 0;
      overflow: hidden;
      transition: opacity $transition-default, width $transition-default 200ms;
      width: 0;
    }
  }

  .menu-search-link {
    cursor: none;
    text-decoration: none;
  }

  .menu-search-btn {
    pointer-events: none;
  }

  .menu-search-input {
    @include field-reset;
    @include placeholder($color-white);
    @include label($color-white);
    border-bottom: 1px solid transparent;
    cursor: none;
    flex-grow: 1;
    margin-left: pxrem(18);
    padding: pxrem(5) pxrem(10) pxrem(5) 0;
    transition: border-bottom $transition-default;

    &:focus {
      border-color: $color-white;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $color-blue inset;
    color: $color-white;
  }

  .menu-search-link-wrapper {
    align-items: center;
    display: flex;

    &.menu-search-link-wrapper--desktop {
      display: none;

      @include mq(md) {
        display: flex;
      }
    }

    &.menu-search-link-wrapper--mobile {
      display: flex;

      @include mq(md) {
        display: none;
      }
    }
  }

  .full-menu {
    background-color: $color-blue;
    display: flex;
    flex-direction: column;
    height: calc(100svh - $header-height);
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: $header-height;
    transition: all $transition-default;
    width: 100vw;
    z-index: -1;

    @include mq(md) {
      display: block;
      height: auto;
    }
  }

  .full-menu-body-external {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    @include mq(md) {
      display: block;
      flex-grow: 0;
      overflow: hidden;
    }
  }

  .full-menu-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      display: block;
      padding-top: pxrem(40);
      position: relative;
    }

    @include mq(xl) {
      @include make-col(20);
      @include make-col-offset(2);
    }

    @include mq(xxl) {
      padding-top: pxrem(80);
    }
  }

  .menu-main {
    width: 100%;

    // first level menu
    > .menu-list {
      @include list-reset;
      @include make-col-ready();
      margin-top: pxrem(35);
      padding-bottom: pxrem(50);

      @include mq(md) {
        @include make-col(9);
        border-right: 1px solid $color-gray-blue;
        margin-top: 0;
        position: relative;
        padding-bottom: pxrem(30);
        padding-top: pxrem(30);
      }

      @include mq(xxl) {
        @include make-col(7);
        padding-bottom: pxrem(50);
        padding-top: pxrem(30);
      }

      > .menu-item {
        &.active {
          > .menu-nolink {
            opacity: 1;
          }

          > .menu-list {
            opacity: 1;
            pointer-events: all;
          }
        }

        > .menu-nolink {
          align-items: center;
          display: inline-flex;
          transition: opacity $transition-default;

          @include mq(md) {
            opacity: 0.2;
          }

          &:hover {
            opacity: 1;
          }

          .menu-nolink-number {
            @include label($color-white);
            align-items: center;
            aspect-ratio: 1;
            border: 1px solid $color-gray-blue;
            border-radius: 50%;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            height: pxrem(40);
            padding: pxrem(5);
            width: pxrem(40);
          }

          .menu-nolink-label {
            @include menu-typo($color-white);
            margin-left: pxrem(20);

            @include mq(xl) {
              margin-left: pxrem(36);
            }
          }
        }

        // submenu
        > .menu-list {
          @include list-reset;
          @include make-col-ready();
          background-color: $color-blue;
          height: calc(100vh - $header-height - $menu-footer-height - 32px);
          left: 0;
          margin-top: pxrem(32);
          max-width: 200%;
          opacity: 0;
          overflow: auto;
          padding-bottom: pxrem(50);
          pointer-events: none;
          position: fixed;
          top: $header-height;
          transition: opacity $transition-default;
          width: 100vw;
          z-index: 1;

          @include mq(md) {
            background-color: transparent;
            column-gap: pxrem(30);
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            height: auto;
            left: col-width(10);
            margin-top: 0;
            overflow: hidden;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            position: absolute;
            top: pxrem(20);
            width: col-width(11);
          }

          @include mq(xl) {
            left: col-width(11);
            width: col-width(10);
          }

          @include mq(xxl) {
            left: col-width(9);
            width: col-width(12);
          }

          .menu-item {
            margin-bottom: pxrem(4);
            margin-top: pxrem(4);

            // back menu
            &.menu-nolink.menu-item--level-1 {
              align-items: center;
              display: inline-flex;

              @include mq(md) {
                display: none;
              }

              .menu-nolink-number {
                @include label($color-white);
                align-items: center;
                aspect-ratio: 1;
                border: 1px solid $color-gray-blue;
                border-radius: 50%;
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                height: pxrem(40);
                padding: pxrem(5);
                width: pxrem(40);
              }

              .menu-nolink-label {
                @include menu-typo($color-white);
                margin-left: pxrem(20);

                @include mq(xl) {
                  margin-left: pxrem(36);
                }
              }
            }
          }

          .menu-link {
            @include display-3($color-white);
            align-items: center;
            display: flex;
            text-decoration: none;
            transition: opacity $transition-default;

            @include mq(md) {
              opacity: 0.2;
            }

            &:hover {
              opacity: 1;

              .menu-link-btn {
                opacity: 1;
              }
            }

            .menu-link-btn {
              margin-left: pxrem(15);
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .full-menu-body-secondary {
    @include make-col-ready;
    margin-top: auto;

    @include mq(md) {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .full-menu-body-secondary-store {
    &.full-menu-body-secondary-store--mobile {
      border-top: 1px solid $color-gray-blue;
      margin-top: pxrem(30);
      padding-bottom: pxrem(30);
      padding-top: pxrem(30);

      @include mq(md) {
        display: none;
      }

      .btn {
        display: block;
        text-align: center;
      }
    }
  }

  .full-menu-footer {
    @include make-col-ready();
    align-items: center;
    border-top: 1px solid $color-gray-blue;
    display: flex;
    height: $menu-footer-height;
    // justify-content: space-between;
    padding-bottom: pxrem(20);
    padding-top: pxrem(20);

    @include mq(md) {
      @include make-col(22);
      @include make-col-offset(1);
      height: auto;
      margin-top: pxrem(40);
      padding-bottom: pxrem(35);
      padding-top: pxrem(35);
    }
  }

  .full-menu-footer-center {
    border-left: 1px solid $color-gray-blue;
    padding-left: pxrem(25);
  }

  .full-menu-footer-right {
    margin-left: auto;
  }

  .footer-secondary-menu {
    &.footer-secondary-menu--desktop {
      display: none;

      @include mq(md) {
        display: block;
      }
    }

    &.footer-secondary-menu--mobile {
      border-bottom: 1px solid $color-gray-blue;
      margin-bottom: pxrem(30);
      padding-bottom: pxrem(30);

      @include mq(md) {
        display: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .btn--collectors {
    margin-bottom: pxrem(10);
  }
}
</style>
